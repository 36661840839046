import { CommonHelper } from 'src/helper/common-helper';
import { LoaderService } from './../../../app/loader/loader.service';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { CommonApiService } from './../../../helper/common-api.service'
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as JWT from 'jwt-decode';
import { AuthHelper } from 'src/helper/auth-helper';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuthenticated = false;
  constructor(private http:HttpClient,private commonApiService:CommonApiService,private router:Router
    ,private LoaderService:LoaderService,private commonData : CommonHelper
    ) { }

    login(params: any) {
      return new Promise((resolve, reject) => {
        this.LoaderService.show()
        this.commonApiService.getPromiseResponse({
          apiName: '/api/login', methodType: 'post',
          parameterObject: params
        }).then(result => {
          this.LoaderService.hide()
          if (result.id) {
            localStorage.setItem('token', result.token);
            localStorage.setItem('id', result.id);
            localStorage.setItem('userName', result.userName);
            this.isAuthenticated = true;
            this.ValidateJWT();
          }
          resolve(result);
        }).catch((error: any) => {
          this.LoaderService.hide()
          reject(error);
        });
      });
    }


  ValidateJWT() {
    try {
      let token = JSON.parse(JSON.stringify(JWT.default(localStorage.getItem('token') || '')));
      if (token && token.AccountData) {
        var userData = JSON.parse(token.AccountData)
        AuthHelper.UserDetails.Email = userData.Email;
        AuthHelper.UserDetails.FirstName = userData.FirstName;
        AuthHelper.UserDetails.LastName = userData.LastName;
        AuthHelper.UserDetails.AccountId = userData.AccountId;
      }
      const date = new Date();
      const timeStamp = date.getTime();
      const timeout = token.exp * 1000 - timeStamp;
      if (timeout < 0) {
        this.logout();
      }
    } catch (e) {
      this.logout();
    }
  }

  HandleAuthRouteAccess() {
    this.ValidateJWT();
    this.router.navigate(['/admin/dashboard']);
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/index'], {
      queryParams: {},
    });
    this.isAuthenticated= false;
  }

  getUserByToken(): Observable<any> {
    const auth = localStorage.getItem('token');
    if (!auth) {
      return of(undefined);
    }
    this.isAuthenticated = true;
    this.ValidateJWT();
    this.getCommonData()
    return of();

  }

  getCommonData()
  {
    this.commonApiService.getPromiseResponse({
      apiName:'/api/data',methodType:'get',parameterObject: null
    }).then((result:any) => {
      var t = JSON.parse(result.data)
      this.commonData.updateCommonText(t);
    }).catch((err:any)=>{
      
    });

  }
  
  ngOnDestroy() {
    
  }
}
