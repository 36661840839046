import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/pages/admin/login/auth.service';
import { MessageService } from 'src/helper/message.service';
import { Router } from '@angular/router';

@Injectable()
export class MyInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private messageservice: MessageService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = localStorage.getItem('token');

        if (token) {
            this.authService.ValidateJWT();
            req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
        }

      return next.handle(req)
        .pipe(catchError(err => {
            if ([401].indexOf(err.status) !== -1) {
              this.authService.logout();
              this.messageservice.warningMessage("Unauthoeized acess");
              return next.handle(err);
        }
        if ([403].indexOf(err.status) !== -1) {
          this.authService.logout();
          this.messageservice.warningMessage("Access Denied");
          return next.handle(err);
        }
        return next.handle(req);
        }))
    }
}